import { useMemo } from "react";
import {
  get,
  set,
  merge,
  omitBy,
  isNil,
  curry,
  mapKeys,
  isEmpty,
  toPairs,
  sortBy,
  compose,
  flatten,
  fromPairs,
  mapValues,
  isString,
  trim,
  omit,
  isObject,
  isArray,
  map,
} from "lodash/fp";

/**
 * trim every string attributes of an object
 * @param {object}
 * @returns {object}
 */
export const trimObject = mapValues((v) => (isString(v) ? trim(v) : v));

/**
 * clone and omit the `null` and `undefined` fields of an object
 * @param {object}
 * @returns {object}
 */
export const omitNullValues = omitBy(isNil);

/**
 * merge two or more objects, while ignores or skips `null` and `undefined` fields
 * @param {...object} objects
 * @returns {object}
 * @example
 * const first = { a: 1, b: null }
 * const second = { a: null, b: 2, c: 2 }
 * const third = { a: 3, b: undefined, c: 3 }
 * mergeIgnoreNil(first, second) // { a: 1, b: 2, c: 2 }
 * mergeIgnoreNil(first, third) // { a: 3, b: null, c: 3 }
 * mergeIgnoreNil(first, second, third) // { a: 3, b: 2, c: 3 }
 */
export const mergeIgnoreNil = (a, b, ...others) => {
  if (a === undefined || b === undefined) return a;

  const merged = merge(a, omitNullValues(b));
  if (!others.length) {
    return merged;
  } else {
    const [next, ...remaining] = others;
    return mergeIgnoreNil(merged, next, ...remaining);
  }
};

/**
 * rename object keys
 * @param {object} mapping
 * @param {object} object
 * @returns {object}
 * @example
 * const data = { a: 1, b: 2 }
 * renameKeys({ a: 'foo', b: 'bar' }) // { foo: 1, bar: 2 }
 */
export const renameKeys = curry((mapping, object) =>
  mapKeys((key) => mapping[key] || key, object),
);

/**
 * check and return the original object, unless it is an empty object, it returns undefined
 * @param {object} object
 * @returns {object|undefined}
 */
export const ignoreEmpty = (object) => (isEmpty(object) ? undefined : object);

export const assertAnyFieldBy = curry((func, object) =>
  toPairs(object).some(([key, value]) => func(value, key)),
);

export const assertAllFieldsBy = curry((func, object) =>
  toPairs(object).every(([key, value]) => func(value, key)),
);

/**
 * merge status flags from apollo statuses
 * @param  {...object} statuses
 * @returns {object}
 * mergeRequestStatus(saved, mutated);
 * // {
 * //   called: true,
 * //   loading: false,
 * //   data: false,
 * //   error: "first error message",
 * //   errors: ["first error message", "second error message"],
 * // }
 */
export const mergeRequestStatus = (...statuses) =>
  statuses.reduce(
    (p, c) => ({
      called: p.called || c.called,
      loading: p.loading || c.loading,
      data: p.data || c.data,
      error: p.error || c.error,
      errors: [].concat(p.errors || [], c.error || []),
    }),
    { called: false, loading: false, error: undefined, errors: [] },
  );

/**
 * memo and merge status flags from apollo statuses
 * @param  {...object} statuses
 * @returns  {object}
 * useMergeRequestStatus(saved, mutated);
 * // {
 * //   called: true,
 * //   loading: false,
 * //   data: false,
 * //   error: "first error message",
 * //   errors: ["first error message", "second error message"],
 * // }
 */
export const useMergeRequestStatus = (...statuses) =>
  useMemo(() => mergeRequestStatus(...statuses), statuses);

/**
 * similar to _.toPairs, but sort the result by key
 * @param {object}
 */
export const toSortedPairs = compose(sortBy(get(0)), toPairs);

/**
 * enhance of useMemo, do simple === check to each key value pairs of an object,
 * it return the previous object if the equal test pass.
 * @template T
 * @param {T} object
 * @returns {T}
 *
 * @example
 * const loading = saved.loading;
 * const called = saved.called;
 * const error = saved.error;
 * // before
 * const status = useMemo(
 *   () => ({ loading, called, error }),
 *   [loading, called, error],
 * );
 * // after
 * const status = memoObjectByKeyValues({ loading, called, error })
 */
export const memoObjectByKeyValues = (object) => {
  const pairs = toSortedPairs(object);
  const deps = flatten(pairs);
  return useMemo(() => fromPairs(pairs), deps);
};

/**
 * Clone and omit properties deeply.
 * @template T
 * @param {String[]} paths
 * @param {T} object
 * @returns {T}
 */
export const omitDeep = curry((paths, input) =>
  isArray(input)
    ? map(omitDeep(paths))(input)
    : isObject(input)
      ? compose(mapValues(omitDeep(paths)), omit(paths))(input)
      : input,
);

/**
 * Ensure and case a specific property on the object is a boolean.
 * @template T
 * @param {String} path
 * @param {T} object
 * @returns {T}
 */
export const ensureFieldIsBoolean = curry((path, obj) =>
  set(path, !!get(path, obj), obj),
);

/**
 * Ensure and case a specific property on the object is an array.
 * @template T
 * @param {String} path
 * @param {T} object
 * @returns {T}
 */
export const ensureFieldIsArray = curry((path, obj) =>
  isArray(get(path, obj)) ? obj : set(path, [], obj),
);
