import { safeFunction, tryCatch } from "./function";

let _isLocalStorageEnabled = false;
try {
  localStorage.getItem("test");
  _isLocalStorageEnabled = true;
} catch (e) {
  // do nothing
}

export const isLocalStorageEnabled = _isLocalStorageEnabled;

export const safeLocalStorage = (function () {
  let cache = {};
  return {
    getItem: tryCatch(
      ({ args }) => cache[args?.[0]] ?? null,
      (key) => localStorage.getItem(key),
    ),
    setItem: tryCatch(
      ({ args }) => {
        cache[args?.[0]] = args?.[1];
      },
      (key, value) => localStorage.setItem(key, value),
    ),
    removeItem: tryCatch(
      ({ args }) => {
        delete cache[args?.[0]];
      },
      (key) => localStorage.removeItem(key),
    ),
    clear: tryCatch(
      () => {
        cache = {};
      },
      () => localStorage.clear(),
    ),
  };
})();
