import errorNotifier from "../shared/modules/error-notifier";

// We use a different access token just for Shop Now. This token has a rate limit,
// because sometimes third-party errors happen with great frequency, which could
// cause a big Rollbar bill for us if we didn't have a rate limit.
export const init = () => {
  // eslint-disable-next-line no-undef
  errorNotifier.init(NVO_CONFIG.rollbarKeyForShopNow, NVO_CONFIG.environment, {
    captureUncaught: false, // disabled to not report all uncaught exceptions from the window of the retailers storefront where this js pack is loaded.
    captureUnhandledRejections: false,
    autoInstrument: {
      log: false,
    },
  });
};

export const configure = errorNotifier.configure;

const buildLogFunction = (level) => (message, meta) => {
  const msg = message?.error?.message || message?.message || message;
  return errorNotifier[level](`shopnow: ${msg}`, {
    originalMessage: message,
    shopnow: true,
    storefront: true,
    ...meta,
  });
};

// These are used in the shop_now.js pack handled errors. Uncaught Errors and Unhandled Rejections would use the log functions from init.
export const critical = buildLogFunction("critical");
export const error = buildLogFunction("error");
export const warn = buildLogFunction("warn");
export const log = buildLogFunction("log");
export const debug = buildLogFunction("debug");
