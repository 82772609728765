import { curry } from "lodash/fp";

/**
 * @param {Function} catchCallback
 * @param {Function} func
 * @returns {Function} Try-catch wrapped `func`
 *
 * @example
 * const parseJson = str => JSON.parse(str);
 * const parseJsonSave = tryCatch(
 *   ({ args, error }) => console.warn(`parseJson(${...args}) threw: ${error}`),
 *   parseJson
 * );
 * parseJsonSave('"hi'"); // return "hi"
 * parseJsonSave('hi'); // warn there is an error
 */
export const tryCatch = curry((catchCallback, func) => (...args) => {
  try {
    return func(...args);
  } catch (error) {
    return catchCallback({ args, error });
  }
});

/**
 * @param {*} defaultValue
 * @param {Function} func
 * @returns {Function} Try-catch wrapped `func`
 *
 * @example
 * const parseJson = str => JSON.parse(str);
 * const parseJsonSave = safeFunction(null, parseJson);
 * parseJsonSave('"hi'"); // return "hi"
 * parseJsonSave('hi'); // return null
 */
export const safeFunction = curry((defaultValue, func) =>
  tryCatch(() => defaultValue, func),
);
