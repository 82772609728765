// Upon clicking "Shop Now" button in our portal, user is redirected to https://peninsulatrading.co/?nvcode=abc
// That triggers a new page load. This JS file is loaded and the presence of the key is checked to initiate Shop Now.
// Add banner UI telling the user they are in Shop Now and credit will be applied at checkout.
// When the checkout button on the cart page is clicked, post the variant IDs and quantity to the backend. Receive checkoutUrl. Redirect.

import "./polyfill";
import moment from "moment";
import {
  camelCase,
  compose,
  fromPairs,
  groupBy,
  identity,
  map,
  merge,
  pick,
  toPairs,
} from "lodash/fp";

import {
  PARAM_SHOP_ID,
  PARAM_CODE,
  PARAM_RETURN_ID,
  PARAM_EXPIRE,
  PARAM_CART_ITEMS,
  PARAM_ORDER_SUMMARY_TEXT,
  CHECKOUT_FORM_SELECTOR,
  CHECKOUT_BUTTON_SELECTOR,
} from "./const";

import {
  clearSession,
  getCache,
  setCache,
  setConfig,
  submitCheckout,
  getShopBranding,
  clearCart,
  hasLocalSession,
  getCart,
  shopNowAbort,
  shopNowCancelReturn,
  getShopNowData,
  getTranslations,
} from "./data";
import * as errorNotifier from "./errorNotifier";
import { isLocalStorageEnabled } from "../shared/modules/localStorage";
import { createBanner, injectCSS } from "./storefront";
import { createFancyBanner } from "./fancybanner";
import { initCheckoutApp } from "./checkout";
import { interpolate } from "../shared/modules/template";

let _initialized = false;
let _checkingOut = false;
let _onClickHandler = () => {};
let _onSubmitHandler = () => {};

/**
 * @typedef InitConfig
 * @type {object}
 * @property {string} [checkoutForm=CHECKOUT_FORM_SELECTOR] CSS selector for checkout form
 * @property {string} [checkoutButton=CHECKOUT_BUTTON_SELECTOR] CSS selector for checkout button
 * @property {boolean} [bindCheckoutListener=true] Flag to indicate application should listener to checkout button onClick and cart form onSubmit events or not. If was set to false, you should call `nv_shopnow.checkout()` yourself and redirect to payment page base on result of the call.
 * @property {boolean} [fallbackEnabled=true] Flag to indicate application should fallback to Shopify Checkout if Narvar Shop Now Checkout is failed
 * @property {HTMLElement|string} [bannerContainer=document.body] CSS selector or the actual container element to place the default store credit and error banner
 * @property {string} [storefrontAccessToken] Storefront access token for accessing cart data through Storefront API. If it is not provided, it fallbacks to use AJAX API instead.
 * @property {Function|string} [cartId] A cart ID string or a function to return a cart ID string, which would be used for querying the cart items during checkout. If it is not provided, it fallbacks to use AJAX API instead.
 * @property {ReadyCallback} [onReady] Callback function when app is initialized and ready. You can create your own banner to show store credit here.
 * @property {CheckoutCallback} [onCheckout] Callback function when checkout form submit or checkout button click is detected, it would be called before submit the checkout. You can perform your own validation logic, loading effect or button disabling here.
 * @property {SuccessCallback} [onSuccess] Callback function when Narvar Shop Now Checkout request is created successfully, it would be called before redirect to the payment page. You can perform your own data cleanup here.
 * @property {ErrorCallback} [onError] Callback function when Narvar Shop Now Checkout request is failed. You can perform your own error handling, error alert, remove loading effect or enable checkout button here.
 */
let _cfg = {
  checkoutButton: CHECKOUT_BUTTON_SELECTOR,
  checkoutForm: CHECKOUT_FORM_SELECTOR,
  bindCheckoutListener: true,
  fallbackEnabled: true,
  bannerContainer: undefined, // `createBanner` function inserts banner to `document.body` by default unless a `null` is passed. If retailer chose to add our script in their html head tag, `document.body` is `null` at this moment.
  onReady,
  onCheckout,
  onSuccess,
  onError,
};

// initialize rollbar
errorNotifier.init();

// initialize shop now app for Shopify Checkout pages
initCheckoutApp();

// initialize shop now app for Storefront general pages
async function initApp(config) {
  if (_initialized) {
    errorNotifier.error("Narvar Shopnow App is initialized before.");
    return;
  }

  // local storage is not support, may cause by browser private mode
  if (!isLocalStorageEnabled) return;

  // update global app config
  _cfg = { ..._cfg, ...config };

  let cache = getCache(true);

  // load parameters
  const urlSearchParams = new URLSearchParams(window.location.search);
  const shopId = urlSearchParams.get(PARAM_SHOP_ID) || cache?.shopId;
  const code = urlSearchParams.get(PARAM_CODE) || cache?.code;
  const returnId =
    urlSearchParams.get(PARAM_RETURN_ID) || cache?.returnId || "";

  // exit script for non-shopnow or already in payment page
  if (!shopId || !returnId || !code || window.Shopify.Checkout?.step) {
    return _cfg.onReady(null);
  }

  // Once the code is present, register that the banner must persist for the
  // duration of the 'session' even. The banner should be there when they
  // navigate to the cart page (where the query parameter will no longer be
  // present); therefore, we cache certain values in localStorage. However,
  // querystring parameters should override cached values if present.

  // TODO:: LIMITATIONS: only works for 1 open shopnow at a time

  const isSessionInit = !cache;

  // overwrite / refresh the local storage, assume the new params coming from
  // URL is from a newer session
  let expiry = urlSearchParams.get(PARAM_EXPIRE);
  expiry = moment(expiry);
  expiry = expiry.isValid() ? expiry : moment().add(6, "h");
  expiry = expiry.toDate().getTime();

  cache = setCache({
    shopId,
    returnId,
    code,
    expiry,
  });

  // Start communicating with backend, get shop now session data
  const shopNowDataResult = await getShopNowData({ code, shopId });
  const shopNowData = shopNowDataResult?.data?.shopNowSession;
  const translations = compose(
    fromPairs,
    map((t) => [t.key, t.value]),
  )(shopNowDataResult?.data?.translations ?? []);
  const {
    expired,
    expiresAt,
    returnStatus,
    abortOptions,
    shopNowAbortEnabled,
    shopNowCheckoutOrderSummaryEnabled,
  } = shopNowData ?? {};

  // Check for session timeout or not. Only clear the local storage when backend
  // explicitly said it is expired. Thus, frontend won't lost the session token
  // code when there is a temporary server down.
  if (expired) {
    clearSession();

    if (returnStatus && returnStatus !== "started") {
      clearCart();
    }

    return _cfg.onReady(null);
  }

  // exit script for another non-shopnow scenario
  const credit = shopNowData?.shopNowCredit?.cents ?? cache?.credit ?? 0;
  if (credit <= 0) {
    return _cfg.onReady(null);
  }

  const currency =
    shopNowData?.shopNowCredit?.currency ?? cache?.currency ?? "USD";
  const creditFormatted =
    shopNowData?.shopNowCredit?.formattedAmount ?? cache?.creditFormatted ?? "";
  // @deprecated it is a feature we never rollout (support exchanges or mix cart
  // in shop now). Keep it unchanged for now.
  const exchangeItemIds = (urlSearchParams.get(PARAM_CART_ITEMS) || "")
    .split(",")
    .filter(identity);
  const exchangeItems = compose(
    map(([key, value]) => ({
      id: key, // variant ID
      quantity: value.length,
    })),
    toPairs,
    groupBy(identity),
  )(exchangeItemIds);
  const enableOrderSummary =
    shopNowCheckoutOrderSummaryEnabled ?? cache?.enableOrderSummary ?? false;
  // TODO: get from translation API
  const orderSummaryText =
    urlSearchParams.get(PARAM_ORDER_SUMMARY_TEXT) || cache?.orderSummaryText;

  const originalPaymentRefundAmount = abortOptions?.find(
    (opt) => opt.type === "original_payment",
  )?.refundAmount?.formattedAmount;
  const giftCardRefundAmount = abortOptions?.find(
    (opt) => opt.type === "gift_card",
  )?.refundAmount?.formattedAmount;

  // save result in cache
  cache = setCache({
    currency,
    credit,
    creditFormatted,
    originalPaymentRefundAmount,
    giftCardRefundAmount,
    exchangeItemIds,
    exchangeItems,
    expiry: moment.unix(expiresAt).toDate().getTime(),
    enableOrderSummary,
    orderSummaryText,
    // set by backend based on feature flag, cached on Shop Now init; governs
    // usage of new Shop Now banner, which has refund/cancel options (see
    // fancybanner.js) vs the classic one, which simply displays the credit
    // amount (see storefront.js)
    shopNowAbortEnabled,
  });
  setConfig({ translations });

  // add items to shopping cart during the first redirection
  // load branding config during the first redirection
  if (isSessionInit) {
    // add exchange items to cart
    if (exchangeItems.length) {
      const itemsToAdd = exchangeItems.map(
        merge({ properties: { "Narvar Exchange": true } }),
      );
      const data = { items: itemsToAdd };
      console.debug("add exchange items to cart", itemsToAdd);

      fetch("/cart/add.js", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          console.debug("Successfully added items to cart:", data);
        })
        .catch((error) => {
          console.error("Error occurred when adding items to cart");
          errorNotifier.error(error);
        });
    }

    try {
      const branding = await getShopBranding(cache.shopId);
      setConfig({ branding });
    } catch (err) {
      errorNotifier.error(err);
    }
  }

  const data = pick([
    "credit",
    "creditFormatted",
    "originalPaymentRefundAmount",
    "giftCardRefundAmount",
    "currency",
    "exchangeItems",
    "expiry",
    "shopNowAbortEnabled",
  ])(cache);
  _cfg.onReady(data);

  document.body.classList.add("narvar_storefront_exchange_active");

  if (_cfg.bindCheckoutListener) {
    _onClickHandler = onClickHandlerFactory(checkout);
    _onSubmitHandler = onSubmitHandlerFactory(checkout);

    document.body.addEventListener("click", _onClickHandler);
    document.body.addEventListener("submit", _onSubmitHandler);
  }
  _initialized = true;
}

// Delegate event listener on checkout button and checkout form
// since checkout component maybe dynamic create,
// while user adding line item to cart or open a side drawer
function onClickHandlerFactory(
  callback,
  buttonSelector = _cfg.checkoutButton,
  formSelector = _cfg.checkoutForm,
) {
  return function onClickHandler(event) {
    const el = event.target;
    const button = el.closest(buttonSelector);
    const form = button && el.closest(formSelector);

    if (button) {
      return callback(button || form, event);
    }
  };
}

function onSubmitHandlerFactory(
  callback,
  buttonSelector = _cfg.checkoutButton,
  formSelector = _cfg.checkoutForm,
) {
  return function onSubmitHandler(event) {
    const el = event.target;
    const form = el.closest(formSelector);
    const button =
      form &&
      (el.querySelector(buttonSelector) ||
        document.querySelector(buttonSelector)); // fallback, sometime the theme designer carelessly put the checkout button outside the form

    const submitTrigger =
      event.explicitOriginalTarget ||
      event.relatedTarget ||
      document.activeElement;
    const isPressingEnter = submitTrigger?.type !== "submit"; // form submit that is not triggered by a submit button
    const isCheckoutButton = submitTrigger?.name?.toLowerCase() === "checkout"; // there could be other types of submit button (e.g. update cart)
    if (form && (isPressingEnter || isCheckoutButton)) {
      return callback(button || form, event);
    }
  };
}

function checkout(target, event) {
  if (!_initialized || _checkingOut) return;
  _checkingOut = true;
  if (event) event.preventDefault();
  const cache = getCache(true);
  const _target =
    target ||
    document.querySelector(_cfg.checkoutButton) ||
    document.querySelector(_cfg.checkoutForm);

  return Promise.resolve(cache)
    .then(async () => {
      if (!cache) throw Error("Session expired");
    })
    .then(() => _cfg.onCheckout(_target))
    .then((ok) => {
      // stop checkout if callback return false
      if (ok === false) throw Error("onCheckout invalid");
    })
    .then(async () => {
      const cartId = await Promise.resolve(
        typeof _cfg.cartId === "function" ? _cfg.cartId() : _cfg.cartId,
      );
      return getCart(cartId, _cfg);
    })
    .then((data) => {
      const items = data.items.map((item) => ({
        productId: item.product_id,
        variantId: item.variant_id,
        quantity: item.quantity,
        properties: item.properties,
      }));
      const { code } = cache;

      return submitCheckout({ items, code, cartToken: data.token });
    })
    .then((result) => {
      console.debug("Success:", result);
      console.debug("result.weburl", result.weburl);
      // wait for onSuccess callback
      // no matter the callback is success or not, it redirect to payment page
      return Promise.resolve(_cfg.onSuccess(result, _target)).then(() => {
        // redirect to checkout page when it was called by event listener
        if (_cfg.bindCheckoutListener && target instanceof HTMLElement) {
          window.location.href = result.weburl;
        }
        return result;
      });
    })
    .catch((error) => {
      _checkingOut = false;
      console.error("checkout error");
      errorNotifier.error(error);
      if (_cfg.fallbackEnabled) {
        document.body.removeEventListener("click", _onClickHandler);
        document.body.removeEventListener("submit", _onSubmitHandler);
      }
      _cfg.onError(error, _target);
    });
}

/**
 * Item to be changed
 * @typedef ExchangeItem
 * @type {object}
 * @property {string} id Shopify variant ID
 * @property {number} quantity quantity of exchange item
 */
/**
 * Narvar Shop Now store credit
 * @typedef ShopNowCredit
 * @type {object}
 * @property {number} credit store credit of Narvar Shop Now exchange in cents
 * @property {string} creditFormatted store credit formatted string
 * @property {string} currency store credit currency
 * @property {ExchangeItem} exchangeItems items which are specified to be changed in Narvar return and exchange form, it would be automatically added to the Shopify shopping cart
 * @property {number} expiry store credit expiry timestamp
 */
/**
 * Callback function when app is initialized and ready. You can create your own banner to show store credit here.
 * @callback ReadyCallback
 * @param {ShopNowCredit} [data=null] data of store credit and exchange items, it could be null if store credit does not exist.
 * @example
 * function custom_shop_now_on_ready_function(data) {
 *   const bannerEl = document.createElement('div');
 *   bannerEl.classList.add('banner');
 *   bannerEl.innerText = `Full Store Exchange Credit: ${data.creditFormatted}`;
 *   const target = document.querySelector('header');
 *   target.append(bannerEl);
 * }
 */

async function onShopNowAbort(refundMethod) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const cache = getCache(true);
  const returnId =
    urlSearchParams.get(PARAM_RETURN_ID) || cache?.returnId || "";
  const shopId = urlSearchParams.get(PARAM_SHOP_ID) || cache?.shopId;

  const { abortStatus, errors, totalRefundFormatted } = await shopNowAbort({
    returnId,
    refundMethod,
    shopId,
  });
  return { abortStatus, errors, totalRefundFormatted };
}

async function onCancelReturn() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const cache = getCache(true);
  const returnId =
    urlSearchParams.get(PARAM_RETURN_ID) || cache?.returnId || "";
  const shopId = urlSearchParams.get(PARAM_SHOP_ID) || cache?.shopId;

  const { errors } = await shopNowCancelReturn({ shopId, returnId });

  return errors;
}

function onReady(data) {
  if (!data) return;

  if (data.shopNowAbortEnabled) {
    injectCSS();

    return createFancyBanner({
      target: this.bannerContainer,
      creditFormatted: data?.creditFormatted,
      originalPaymentRefundAmount: data?.originalPaymentRefundAmount,
      giftCardRefundAmount: data?.giftCardRefundAmount,
      onCancelReturn,
      onShopNowAbort,
    });
  }

  // inject custom css
  injectCSS();

  // create banner
  const translations = getTranslations();
  return createBanner({
    target: this.bannerContainer,
    message: interpolate(
      translations.shop_now_banner_copy ??
        "%{amount} in credit will be applied at checkout",
      { amount: data.creditFormatted },
    ),
  });
}

/**
 * Callback function when checkout form submit or checkout button click is detected, it would be called before submit the checkout. You can perform your own validation logic, loading effect or button disabling here.
 * @callback CheckoutCallback
 * @param {HTMLElement} target the checkout button or checkout form
 * @returns {boolean} return false to cancel the checkout
 */
function onCheckout(target) {
  if (!target) return true;

  target.disabled = true;
  const appendSpinner =
    target.tagName.toLowerCase() === "input" || target.innerHTML.trim() === "";
  const el = document.createElement("div");
  el.className = "narvar__spinner";
  if (appendSpinner) {
    el.classList.add("narvar__spinner--dark");
    target.insertAdjacentElement("afterend", el);
  } else {
    target.insertAdjacentElement("beforeend", el);
  }

  return true;
}

/**
 * Narvar Shop Now Checkout successful result
 * @typedef CheckoutResult
 * @type {object}
 */
/**
 * Callback function when Narvar Shop Now Checkout request is created successfully, it would be called before redirect to the payment page. You can perform your own data cleanup here.
 * @callback SuccessCallback
 * @param {CheckoutResult} result checkout success result
 * @param {HTMLElement} target the checkout button or checkout form
 */
function onSuccess(result, target) {
  // do nothing, keep disabling the checkout button and the spinner,
  // wait for redirecting to the shopify payment page
}

/**
 * Callback function when Narvar Shop Now Checkout request is failed. You can perform your own error handling, error alert, remove loading effect or enable checkout button here.
 * @callback ErrorCallback
 * @param {Error} error the error object
 * @param {HTMLElement} target the checkout button or checkout form
 */
function onError(error, target) {
  if (target) target.disabled = false;
  document.querySelectorAll(".narvar__spinner").forEach((el) => el.remove());

  const translations = getTranslations();
  createBanner({
    message:
      translations.shop_now_banner_checkout_error ??
      "Unable to checkout, please try again later.",
    color: "error",
    dismiss: 8,
  });
}

const previewBanner = (function () {
  let _prevBanner = null;

  return (bannerContainer) => {
    const backup = _cfg.bannerContainer;
    try {
      if (bannerContainer) _cfg.bannerContainer = bannerContainer;
      if (_prevBanner) {
        _prevBanner.remove();
      }

      _prevBanner = _cfg.onReady({ creditFormatted: "$150.00" });
    } finally {
      _cfg.bannerContainer = backup;
    }
    return _prevBanner;
  };
})();

const { startDebugCheckout, stopDebugCheckout } = (() => {
  const debugHandler = (target, event) => {
    event.preventDefault();

    console.log(
      `Narvar: Received ${camelCase("on_" + event.type)} event from`,
      target,
    );
    console.log("Narvar: Simulating checkout API calls...");
    let count = 0;
    const timer = setInterval(() => {
      console.log("Narvar: ...");

      if (++count >= 5) {
        clearInterval(timer);
        console.log("Narvar: Success! We are uninterrupted by 3rd parties.");
      }
    }, 1000);
  };
  let onClickDebugger;
  let onSubmitDebugger;

  return {
    startDebugCheckout(config) {
      const checkoutButton = config?.checkoutButton ?? _cfg.checkoutButton;
      const buttons = document.querySelectorAll(checkoutButton);
      const checkoutForm = config?.checkoutForm ?? _cfg.checkoutForm;
      const forms = document.querySelectorAll(checkoutForm);

      console.log("Narvar: config.checkoutButton is", checkoutButton);
      console.log(`Narvar: ${buttons.length} button(s) found:`, buttons);

      console.log("Narvar: config.checkoutForm is", checkoutForm);
      console.log(`Narvar: ${forms.length} form(s) found:`, forms);

      // cleanup
      document.body.removeEventListener("click", _onClickHandler);
      document.body.removeEventListener("submit", _onSubmitHandler);
      document.body.removeEventListener("click", onClickDebugger);
      document.body.removeEventListener("submit", onSubmitDebugger);

      // bind event listeners
      onClickDebugger = onClickHandlerFactory(
        debugHandler,
        checkoutButton,
        checkoutForm,
      );
      onSubmitDebugger = onSubmitHandlerFactory(
        debugHandler,
        checkoutButton,
        checkoutForm,
      );
      document.body.addEventListener("click", onClickDebugger);
      document.body.addEventListener("submit", onSubmitDebugger);

      console.log(
        "Narvar: Debug listeners are bound, please click checkout button to test its connectivity.",
      );
    },

    stopDebugCheckout() {
      document.body.removeEventListener("click", onClickDebugger);
      document.body.removeEventListener("submit", onSubmitDebugger);
      onClickDebugger = null;
      onSubmitDebugger = null;

      if (_cfg.bindCheckoutListener) {
        document.body.addEventListener("click", _onClickHandler);
        document.body.addEventListener("submit", _onSubmitHandler);
      }
      console.log("Narvar: Reverted all binding of debug listeners");
    },
  };
})();

// listen to onLoad event to initialize our shopnow script
function init(config) {
  return new Promise((resolve, reject) => {
    // define timeout handler
    const TEN_SECONDS = 10000;
    const timeoutTimer = setTimeout(
      () =>
        reject(
          new Error(
            "Timeout, failed to initiate Narvar Full-Store Exchanges widget.",
          ),
        ),
      TEN_SECONDS,
    );

    // define onLoad handler (initialize shopnow widget)
    const onLoad = async () => {
      await initApp(config);
      clearTimeout(timeoutTimer);
      resolve();
    };

    // bind onLoad handler
    if (document.readyState === "loading") {
      document.addEventListener("DOMContentLoaded", onLoad);
    } else {
      onLoad();
    }
  });
}

// export function to global
/**
 * Narvar Shop Now App SDK
 * @namespace
 */
const nv_shopnow = {
  /**
   * Initialize the Narvar Shop Now App
   * @function
   * @param {InitConfig} [config]
   */
  init,

  /**
   * Check if there is a Narvar Shop Now session or not
   * @function
   * @returns {boolean} true if there is a Narvar Shop Now session
   */
  hasSession: hasLocalSession,

  /**
   * @typedef ShopNowCheckoutResult
   * @type {object}
   * @property {string} weburl shopify checkout url
   * @property {string} status shopify checkout status, "ok" or "invalid"
   */
  /**
   * Submit Narvar Shop Now Checkout request
   * @function
   * @returns {Promise<ShopNowCheckoutResult>} result of checkout with redirection url
   * @example
   * if(nv_shopnow.hasSession()) {
   *   nv_shopnow.checkout().then(function(res){
   *     if(res.status === 'ok'){
   *       location.href = res.weburl;
   *     }
   *   });
   * }
   */
  checkout,

  /**
   * Return form in iframe(consumer app) may want to cancel and remove the banner
   * @private
   */
  cancel: clearSession,

  /**
   * Preview exchange credit banner on UI
   * @function
   * @property {HTMLElement|string} [bannerContainer] CSS selector or the actual container element to place the store credit banner, it defaults to the initial configure.
   * @returns {HTMLElement} The banner element
   */
  previewBanner,

  /**
   * Swap the checkout listeners to debugging listeners on checkout buttons and
   * forms. It logs useful information for debugging.
   * @function
   * @property {object} [config] Configuration object
   * @property {string} [config.checkoutButton] CSS selector of checkout buttons
   * @property {string} [config.checkoutForm] CSS selector of checkout forms
   */
  startDebugCheckout,

  /**
   * Revert the debug listeners from startDebugCheckout() function
   * @function
   */
  stopDebugCheckout,

  /**
   * Default CSS selector for checkout form
   * @constant {string}
   */
  CHECKOUT_BUTTON_SELECTOR,

  /**
   * Default CSS selector for checkout button
   * @constant {string}
   */
  CHECKOUT_FORM_SELECTOR,
};
window.nv_shopnow = Object.assign(window.nv_shopnow || {}, nv_shopnow);
