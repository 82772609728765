import { template, unescape } from "lodash/fp";
import templateSettings from "lodash/templateSettings";
import errorNotifier from "./error-notifier";

export const TEMPLATE_VAR_REGEX = /%{([\s\S]+?)}/g;

templateSettings.escape = TEMPLATE_VAR_REGEX;
templateSettings.evaluate = TEMPLATE_VAR_REGEX;
templateSettings.interpolate = TEMPLATE_VAR_REGEX;

export const interpolate = (string, variables) => {
  try {
    return unescape(template(string)(variables));
  } catch (err) {
    console.warn(err);
    console.warn(`Template interpolate error: "${string}"`, variables);
    err.message = "Template interpolate error";
    errorNotifier.warn(err, { template: string, variables });
    return unescape(string);
  }
};
