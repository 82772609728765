import {
  CHECKOUT_PAYMENT_STEP,
  CHECKOUT_SHIPPING_STEP,
  CHECKOUT_SUCCESS_STEP,
} from "./const";
import { clearCart, clearSession, getCache } from "./data";

export const injectOrderSummary = () => {
  const cache = getCache(true);

  // If there is a Shop Now session. NOTE: cache is only present when the
  // storefront redirect and checkout live at the same domain. If the storefront
  // lives at a root domain but the checkout at the myshopify subdomain, then
  // the localStorage lookup for this cache will return nothing.
  if (cache && cache?.code) {
    const message = cache.orderSummaryText;
    const elClassName = "narvar_shopnow_summary_text";

    const existingElements = document.querySelector("." + elClassName);

    if (!existingElements) {
      const containerEl = document.createElement("div");
      containerEl.className = "section";
      const el = document.createElement("p");
      el.className = elClassName;
      el.innerText = message;
      const target = document.querySelector(".step__footer");
      containerEl.appendChild(el);
      target.insertAdjacentElement("afterend", containerEl);
    }
  }
};

export const initCheckoutApp = () => {
  // clear local storage and shopping cart when checkout success
  if (window.Shopify?.Checkout?.step === CHECKOUT_SUCCESS_STEP) {
    console.debug("shopnow checkout success");

    injectOrderSummary();

    clearSession();
    console.debug("shopnow session cleared");

    clearCart();
  }

  if (
    window.Shopify?.Checkout?.step === CHECKOUT_SHIPPING_STEP ||
    window.Shopify?.Checkout?.step === CHECKOUT_PAYMENT_STEP
  ) {
    injectOrderSummary();
  }
};
