import fetch from "unfetch";
import { ApolloClient, HttpLink, ApolloLink } from "@apollo/client";
import { InMemoryCache } from "@apollo/client/cache";
import { onError } from "@apollo/client/link/error";
import get from "lodash/get";
import last from "lodash/last";

import errorNotifier from "../../shared/modules/error-notifier";

import {
  ERROR_EVENTS,
  emitSessionInvalid,
  emitSessionStale,
  emitGeneralError,
  emitShopUserUnauthorized,
} from "./error-emitter";
import { authenticatedFetch } from "@shopify/app-bridge-utils";

export const createCache = (config) => {
  const cache = new InMemoryCache(config);
  if (process.env.NODE_ENV === "development") {
    window.secretVariableToStoreCache = cache;
  }
  return cache;
};

export const getGraphQLPath = () => {
  const shid = get(window, "nvo_config.shid");
  // TODO: may need to add this back in for the webpack dev app.
  // if (last(pathname) === "/") return `${pathname}graphql?shid=${shid}`;
  return `${window.location.origin}/app_proxy/graphql?shid=${shid}`;
};

const handleErrorByCode = (err, operationName, isNetwork) => {
  // If the graphql request returns 500, the error object is on err.result.error
  let error = get(err, "result.error") || err;
  const code = get(error, "extensions.code");
  console.error("apollo error", error, "code", code);

  switch (code) {
    case ERROR_EVENTS.sessionStale:
      emitSessionStale(error);
      break;
    case ERROR_EVENTS.sessionInvalid:
      emitSessionInvalid(error);
      break;
    case ERROR_EVENTS.shopUserUnauthorized:
      emitShopUserUnauthorized(error);
      break;
    case ERROR_EVENTS.shopUserUnregistered:
    case ERROR_EVENTS.shopUserUnverified:
    case ERROR_EVENTS.shopUserDisabled:
      // noop to prevent default error banner given the registration/verification dialog will display instead
      break;
    default: {
      // If the error object comes in as { isTrusted: true } only, then
      // it's from the window "error" event listener and CORS is blocking the
      // full error. We can investigate our CORS settings to capture those.
      // https://stackoverflow.com/questions/44815172/log-shows-error-object-istrustedtrue-instead-of-actual-error-data
      // https://blog.sentry.io/2016/05/17/what-is-script-error#the-fix-cors-attributes-and-headers
      const isGenericError = Object.keys(error).every((i) => i === "isTrusted");

      // log error in rollbar
      if (!isGenericError) {
        error = Object.assign({}, error); // clone error object
        let logFunc = errorNotifier.error; // default all apollo errors as `error` log level
        let errorGroup = `GraphQL(${operationName})`; // define a more readable error message
        // meta data for rollbar to query
        const meta = {
          graphql: {
            error: true,
            isNetwork,
            operationName,
          },
        };
        if (isNetwork) {
          // normal API errors would return HTTP 200 and treat as non-network
          // errors, network error throws when received HTTP 4xx, 5xx. These
          // errors should related to backend, however sometime backend cannot
          // catch the error
          // (eg. #4848 PG::InvalidTextRepresentation: ERROR: malformed array literal:)
          const errorName = get(err, "name") || "UnknownNetworkError";
          const statusCode = get(err, "statusCode");
          errorGroup += `: ${errorName}[${statusCode}]`;
          meta.graphql.statusCode = statusCode;
        }
        error.message = `${errorGroup}: ${error.message}`;
        logFunc(error, meta);
      }
      emitGeneralError(error);
      break;
    }
  }
};

const createErrorLink = () =>
  onError(({ graphQLErrors, networkError, operation }) => {
    const logError = (error, operation, isNetwork) => {
      const { operationName } = operation;
      console.debug(
        `GraphQL - ${isNetwork ? "Network" : ""}Error (${operationName})`,
        {
          error,
          operation,
        },
      );
      let errors = isNetwork ? [error] : error;
      errors.forEach((err) => handleErrorByCode(err, operationName, isNetwork));
    };

    if (graphQLErrors) {
      logError(graphQLErrors, operation, false);
    }
    if (networkError) {
      logError(networkError, operation, true);
    }
  });

const isTestEnv = () => {
  return window.nvo_config.environment === "test";
};

/* authenticatedFetch uses the JWT (adds an `Authorization` header)
 * https://shopify.dev/apps/auth/session-tokens#how-session-tokens-work
 *
 * In the test env, we must use an un-authenticated fetch, because we don't
 * have a real `shop_session`.
 */
const fetchFunctionForHttpLink = (app) => {
  return app && !isTestEnv() ? authenticatedFetch(app) : fetch;
};

const createHttpLink = (uri, app) => {
  return new HttpLink({
    uri,
    credentials: "same-origin", // previously, we used "include"
    fetch: fetchFunctionForHttpLink(app),
    includeExtensions: true, // we're keeping this, but we don't know what it does
  });
};

/* Use this ApolloClient for authenticated apps, like the retailer dashboard
 * (retailer-app) IOW, when that app's graphql controller (eg.
 * graphql_controller.rb) inherits AuthenticatedController. */
export const createClient = (cache, app, requestLink = "/graphql") => {
  return new ApolloClient({
    link: ApolloLink.from([
      createErrorLink(),
      createHttpLink(requestLink, app),
    ]),
    cache, // new InMemoryCache()
  });
};

/* Use this ApolloClient for unauthenticated apps, like `consumer-app` and
 * `track-app`. IOW, when that app's graphql controller (eg.
 * AppProxyController#execute_graphql) does not inherit AuthenticatedController.
 *
 * The "token" here used to refer to the [CSRF
 * token](https://guides.rubyonrails.org/security.html#cross-site-request-forgery-csrf)
 * which we no longer use. Now, it refers to the bearer token in the
 * `Authorization` header. */
export const createClientWithoutToken = (cache, uri) => {
  return new ApolloClient({
    link: ApolloLink.from([createErrorLink(), createHttpLink(uri)]),
    cache,
  });
};
